<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-4">
        <div class="sign-up">
          <h1 class="header">
            Ustaw nowe hasło
          </h1>
          <form @submit.prevent="onSubmit">
            <p v-if="isError" class="sign-up__error">
              {{ errorMessage }}
              <router-link :to="{ name: 'password-reset-request' }">
                Ponów resetowanie hasła
              </router-link>
            </p>

            <password-input
              v-model="$v.password.$model"
              label="Podaj nowe hasło"
              autocomplete="off"
              @validation-status-change="onValidationStatusChange"
            />

            <p class="sign-up__submit">
              <button
                class="button"
                type="submit"
                :disabled="!isFormValid || isSuccess"
                :class="{
                  'button--loading': isLoading,
                }"
              >
                Ustaw nowe hasło
              </button>
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import PasswordInput from '@/vue/components/password-input/password-input.vue';

export default {
  name: 'PasswordReset',

  components: { PasswordInput },

  mixins: [validationMixin],

  props: {
    code: {
      type: String,
      required: false,
      default: null,
    },

    email: {
      type: String,
      required: false,
      default: null,
    },
  },

  validations: {
    email: {
      required,
      email,
    },

    password: {
      required,
    },
  },

  data: () => ({
    password: '',
    isError: false,
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
    isPasswordExpiredMessageVisible: false,
    isPasswordValid: false,
  }),

  computed: {
    isFormValid() {
      return !this.$v.$invalid && this.isPasswordValid;
    },
  },

  methods: {
    ...mapActions('user', ['resetPassword', 'signIn']),

    onValidationStatusChange({ isValid }) {
      this.isPasswordValid = isValid;
    },

    async onSubmit() {
      try {
        this.isLoading = true;
        this.isError = false;
        await this.resetPassword({
          email: this.email,
          code: this.code,
          password: this.password,
        });
        await this.handleSuccess();
      } catch (error) {
        console.log('Unable to reset password', error);
        this.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },

    async handleSuccess() {
      await this.signIn({
        email: this.email,
        password: this.password,
      });
      this.isSuccess = true;
      this.$router.push({
        name: 'my-account',
      });
    },

    handleError(e) {
      this.isError = true;

      const responses = [
        {
          code: 'ExpiredCodeException',
          message: 'Wygasł link aktywacyjny',
        },
        {
          code: 'InvalidPasswordException',
          message:
            'Podane hasło jest nieprawidłowe. Spróbuj ustawić inne hasło.',
        },
      ];

      const genericMessage =
        'Wystąpił błąd. Prosimy o kontakt na: info@fotoregiony.pl';

      const found = responses.find(({ code }) => code === e.code);
      this.errorMessage = found ? found.message : genericMessage;
    },
  },
};
</script>
